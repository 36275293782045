.icon-bar {
  position: fixed;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 10px 5px;
  background-color: #374c73;
  border-radius: 0 10px 10px 0;
  z-index: 1;
}

.social-img img {
  height: 30px;
  width: 30px;
}

.social-img {
  padding: 3px 0;
}


@media (max-width: 425px) {
  .social-img img {
    height: 25px;
    width: 25px;
  }
}
